// Brand colors
$Brand_red: #EF0000;
$Brand_blue: #2D61BA;
$Brand_yellow: #F6C325;

// Black and grey
$BaG_black: #131416;
$BaG_gray_one: #333333;
$BaG_gray_two: #4E5355;
$BaG_gray_three: #6C7175;
$BaG_gray_four: #8A8F93;
$BaG_gray_five: #B5B7BA;
$BaG_gray_six: #DEE0E2;
$BaG_gray_seven: #F4F5F6;
$BaG_gray_eight: #F7F8F8;

// Blue colors
$Blue_one: #2C6BC9;
$Blue_two: #5490DE;
$Blue_three: #66B2F7;
$Blue_four: #A1D0FD;

// Red colors
$Red_one: #CE0000;
$Red_two: #F73232;
$Red_three: #FF7070;
$Red_four: #FFD5D2;

// Green colors
$Green_one: #1FC180;
$Green_two: #58DAA6;
$Green_three: #C2FFE2;
$Green_four: #27C175;
$Green_five: #29b16d;

// Yellow colors
$Yellow_one: #F9DA62;
$Yellow_two: #FEEBC3;

// Donor status colors
$Donor_potential: #FFFBD9;
$Donor_novice: #C8FDE0;
$Donor_responsible: #CBFBFE;
$Donor_regular: #A3F6FB;
$Donor_experienced: #DBCEFF;
$Donor_irrepressible: #B499FF;
$Donor_legendary: #5429CC;

// Custom colors
$White: #FFFFFF;
$BorderColor: #DEE0E3;

//Custom border focus color
$BorderFocusColor: #37333F;
