@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
@import "mixins";
@import "colors";

// Fonts
.DH {
  font-style: normal;
  font-family: "Inter", Arial, sans-serif;
  color: $BaG_black;

  &_lvl0 {
    @include S() {
      font-weight: 800;
      font-size: 48px;
      line-height: 48px;
    }
    @include L() {
      font-weight: 900;
      font-size: 64px;
      line-height: 72px;
    }
  }

  &_lvl1 {
    @include S() {
      font-weight: 800;
      font-size: 40px;
      line-height: 44px;
    }
    @include L() {
      font-weight: 900;
      font-size: 56px;
      line-height: 64px;
    }
  }

  &_lvl2 {
    @include S() {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
    }
    @include L() {
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
    }
  }

  &_lvl3 {
    @include S() {
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }
    @include L() {
      font-weight: 800;
      font-size: 28px;
      line-height: 36px;
    }
  }

  &_lvl4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
  }
}

.TTitle {
  font-style: normal;
  font-family: "Inter", Arial, sans-serif;

  &_lvl1 {
    color: $BaG_black;
    @include S() {
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
    }
    @include L() {
      font-weight: 800;
      font-size: 48px;
      line-height: 56px;
    }
  }

  &_lvl2 {
    color: $BaG_black;
    @include S() {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
    }
    @include L() {
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
    }
  }

  &_lvl3 {
    color: $BaG_black;
    @include S() {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
    }
    @include L() {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
    }
  }

  &_lvl4 {
    color: $BaG_black;
    @include S() {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }
    @include L() {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &_lvl5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $BaG_black;
  }

  &_lvl6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: $BaG_gray_two;
  }

  &_button_large {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: $BaG_black;
  }

  &_button_medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $BaG_black;
  }
}

.Lead {
  font-style: normal;
  font-family: "Inter", Arial, sans-serif;
  color: $BaG_gray_two;

  &_0 {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }

  &_1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  &_2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  &_3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &_4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.Text {
  font-style: normal;
  font-family: "Inter", Arial, sans-serif;

  &_text {
    color: $BaG_gray_one;

    &_1 {
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
    }

    &_2 {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }

    &_3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &_4 {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &_link {
    color: $Blue_one;

    &_1 {
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      transition: 0.2s ease-in-out all;

      &:hover {
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1.2px;
      }
    }

    &_2 {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      transition: 0.2s ease-in-out all;

      &:hover {
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1.2px;
      }
    }

    &_3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      &:hover {
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1.2px;
      }
    }
  }
}

.Caption {
  font-style: normal;
  font-family: "Inter", Arial, sans-serif;

  &_1 {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $BaG_gray_one;
  }

  &_2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $BaG_gray_one;
  }

  &_label {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: $BaG_gray_two;
  }

  &_status_label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: $BaG_gray_one;
  }
}

.StaticTitle {
  &_3 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }
}

.Mobile {
  &_lead {
    &_0 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &_1 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &_2 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    &_3 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &_4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.Input {
  @extend .Text_text_3;
  padding: 16px;
  background: $White;
  border: 1px solid $BaG_gray_five;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  transition: 0.2s ease-in-out all;
  color: $BaG_black;

  &[data-error="true"] {
    border: 1px solid $Red_one;
    color: $Red_one;
  }

  &:focus {
    border: 1px solid $BorderFocusColor;
  }

  &::placeholder {
    @extend .Text_text_3;
    color: $BaG_gray_five;
  }
}
