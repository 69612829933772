@import "src/styles/mixins.scss";
@import "src/styles/shortcuts";

.SelectCityModal {
  position: relative;

  &_Close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &_Content {
    padding: 40px;
    display: flex;
    flex-direction: column;

    &_Title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      letter-spacing: -0.01em;
      color: $title;
      margin-bottom: 24px;
    }

    &_Block {
      display: flex;
      align-items: center;
      height: 324px;

      & > div {
        border: 1px solid $inputBack;
        box-sizing: border-box;
        border-radius: 6px;
        cursor: pointer;
        transition: .2s ease-in-out;
        padding: 21px 40px;
        display: flex;
        flex-direction: column;
        max-width: 290px;
        height: 100%;

        & > div {
        }

        &:hover {
          background: $inputBack;
        }
      }

      &_Moscow {
        margin-right: 8px;

        &_Image {
          height: 82px;
          margin: 16px auto 48px auto;
        }

        &_Text {
          display: flex;
          flex-direction: column;

          & > span {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
            color: $title;
            margin-bottom: 16px;
          }

          & > p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.01em;
            color: $lightGrayText;
          }
        }

        &_Active {
          border: 2px solid $red !important;
        }
      }

      &_Other {
        margin-left: 8px;

        &_Image {
          height: 82px;
          margin: 16px auto 48px auto;
        }

        &_Text {
          display: flex;
          flex-direction: column;

          & > span {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
            color: $title;
            margin-bottom: 16px;
          }

          & > p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.01em;
            color: $lightGrayText;
          }

        }

        &_Active {
          border: 2px solid $red !important;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .SelectCityModal {
    &_Content {
      padding: 8px;

      &_Block {
        flex-direction: column;
        width: 100%;
        height: auto;

        & > div {
          flex-direction: row;
          justify-content: space-between;
          max-width: 100%;
          height: auto;
          align-items: center;
          padding: 8px 16px;
          width: 100%;
        }

        &_Moscow {
          margin-bottom: 4px;
          margin-right: 0;

          &_Image {
            margin-right: 32px;
          }

          &_Text {
            & > span, p {
              text-align: left;
            }
          }
        }

        &_Other {
          margin-top: 4px;
          margin-left: 0;

          &_Image {
            margin-right: 32px;
          }

          &_Text {
            & > span, p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
