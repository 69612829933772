@import "src/styles/mixins.scss";
@import "src/styles/shortcuts";

.SpinnerDef {
  height: 100%;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin: auto;
    animation-name: onceRotate;
    animation-duration: 0.6s;
  }
}

.Spinner {
  @extend .SpinnerDef;
  height: 100%;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 100000;
  position: inherit;
}

.SpinnerOnAll {
  @extend .SpinnerDef;
  height: 100%;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100000;
}

@keyframes onceRotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.OnEmptyPage {
  position: relative;
  background: $white;
  height: 60vh;
  @include beMobile() {
    height: 50vh;
  }
}

.OnEmptyPageProfile {
  @extend .OnEmptyPage;
  margin-top: 1px;
}


.SpinnerAnimation {
  animation: spinner 4s linear 0s infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
