@import "colors";

$ff: Inter, Arial, sans-serif !default;
$fst: normal !default;
$fw: 500 !default;
$fsi: 14px !default;
$lh: 17px !default;
$c: #5F6177 !default;
$b: #f6f7f8;

* {
  font-family: $ff;
}

body, html {
  font-family: $ff !important;
  font-style: $fst !important;
  font-weight: $fw !important;
  font-size: $fsi !important;
  line-height: $lh !important;
  color: $c !important;
  background-color: $b !important;
}

header a {
  font-family: $ff;
  font-style: $fst;
  font-weight: $fw;
  font-size: $fsi;
  line-height: $lh;
  color: $c;
}

header a:hover {
  color: $c;
}

.carousel .slide {
  background: unset !important;
}

.carousel .control-dots {
  position: unset !important;
}

.carousel .control-dots .dot {
  transition: opacity .25s ease-in !important;
  opacity: 1 !important;
  filter: alpha(opacity=30) !important;
  box-shadow: unset !important;
  margin: 0 8px !important;
  width: 14px !important;
  height: 14px !important;
  background: #c2d5ef !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  outline: none !important;
}

.carousel .control-dots .dot.selected {
  background: #2d60ba !important;
}

#nprogress .bar {
  background: #F73E3E !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #F73E3E, 0 0 5px #F73E3E !important;
}

#nprogress .spinner-icon {
  border-top-color: #F73E3E !important;
  border-left-color: #F73E3E !important;
}

.cp {
  cursor: pointer;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.preloader {
  animation: 1s linear 0s infinite rotating;
}
