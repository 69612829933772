@import "src/styles/mixins";

$black: black;
$title: #333333;
$link: #3892EA;
$linkActive: #4183c4;
$headerLink: #5F6177;
$headerLinkActive: $title;
$iconGrayBack: #E5E9EE;
$blackText: #37333F;
$lightGrayText: #C1C4CA;
$red: #F63E3E;
$redActive: #FE5252;
$white: white;
$textFiol: #FF4B77;
$inputBack: #F6F7F8;
$btnGreen: #27C175;
$btnGreenActive: #30CA7E;
$mainPageFiol: #FE4A71;
$mainPageRed: #F73232;
$boxShadow: #F2F2F2;
$yellowBorder: #F7C41C;
$colorTitle: #333333;

@keyframes slowlyRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(380deg);
  }
}

@mixin BTN($btnColor, $btnColorActive) {
  background: $btnColor;
  transition: .2s ease-in-out;
  outline: none;
  border: none;
  -webkit-appearance: none;
  &:hover, &:focus, &:active {
    background: $btnColorActive;
    outline: none;
    border: none;
    -webkit-appearance: none;
  }
}

@mixin underLineActiveMenuMob($bottomPosition) {
  content: '';
  position: absolute;
  bottom: $bottomPosition;
  left: 0;
  height: 2px;
  width: 100%;
  background: #F73232;
}

@mixin WhiteBTN($borderColor, $borderColorActive, $color, $colorActive) {
  background-color: $white;
  transition: .2s ease-in-out;
  outline: none;
  border: 1px solid $borderColor;
  -webkit-appearance: none;
  color: $color;
  &:hover {
    border: 1px solid $borderColorActive;
  }
  &:active {
    border: 1px solid $borderColorActive;
    color: $colorActive;
  }
}


.StaticDiv {
  padding: 56px 32px;
  @include beMobile() {
    padding: 92px 8px 32px;
  }
}

.StaticText {
  font-style: normal;
  color: $black;
  margin-bottom: 24px;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-align: justify;
}

.StaticLink {
  color: $link;
  transition: .2s ease-in-out;

  &:hover {
    color: $linkActive;
  }
}

.StaticList {
  margin: 0;
  padding-left: 24px;
  width: 80%;
  @include beMobile() {
    width: 100%;
  }
}

.StaticListItem {
  margin: 0 0 4px;
}

.StaticBlock {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @extend .StaticText;
  margin-bottom: 0;

  & > h1 {
    @extend .H1;
  }

  & > h3 {
    @extend .H3;
  }

  h4 {
    @extend .H4;
  }

  & > span {
    @extend .StaticText;
    margin-bottom: 16px;
  }

  & > p {
    @extend .StaticText;
    margin-bottom: 16px;
  }

  & > ol {
    @extend .StaticList;

    & > li {
      @extend .StaticListItem;
    }
  }

  & > ul {
    @extend .StaticList;

    & > li {
      @extend .StaticListItem;
    }
  }
}

.H1 {
  font-size: 36px;
  font-style: normal;
  line-height: 140%;
  font-weight: 500;
  color: $title;
  margin-bottom: 16px;
  @include beMobile() {
    text-align: left;
    font-size: 32px;
  }
}

.H3 {
  font-size: 24px;
  font-style: normal;
  line-height: 140%;
  font-weight: 500;
  color: $title;
  margin-bottom: 8px;
  @include beMobile() {
    text-align: left;
  }
}

.H4 {
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  font-weight: 500;
  color: $title;
  margin-bottom: 8px;
  @include beMobile() {
    text-align: left;
  }
}
