.gbcomp {
  background: #ffffff;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 4px 25px;
  position: relative;
  margin: 30px 0 0;
  border-radius: 4px;
}

.gbcomp__arrow {
  position: absolute;
  bottom: 8px;
  left: 40%;
  height: 31.679807662963867px;
  width: 263.9078674316406px;
}

.gbcomp_hidden {
  display: none;
}

.gbcomp__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.gbcomp__text-content {
  margin: 0 20px 0 0;
}

.text-content__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #131416;
  margin: 0;
}

.text-content__span {
  color: rgba(255, 42, 35, 1);
}

.text-content__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #131416;
  margin: 0;
}

.gbcomp__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons__get-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  padding: 10px 38px;
  background: #f73232;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  margin-right: 60px;
}

.buttons__get-btn:hover {
  opacity: 0.9;
}

.buttons__close-btn {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 16px;
  height: 16px;
  padding: 0;
}

.close-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

@media (min-width: 1440px) {
  .gbcomp__content {
    width: 85%;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .gbcomp__content {
    width: 90%;
  }
}

@media (max-width: 1199px) {
  .buttons__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .gbcomp__content {
    width: 85%;
    align-items: center;
  }
  .gbcomp__text-content {
    margin: 0;
  }
  .gbcomp__arrow {
    left: 25%;
  }
  .buttons__get-btn {
    margin-right: 20px;
    padding: 10px 15px;
  }
  .text-content__subtitle {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .badgecomp {
    width: 360px !important;
    max-width: 360px !important;
  }
  .gbcomp {
    background: url("../../new-images/mask-group-mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto 30px;
    height: 130px;
    justify-content: flex-end;
  }
  .gbcomp__content {
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
  }
  .buttons__get-btn {
    margin-right: 0;
    background-color: rgba(0, 0, 0, 0);
    color: #ff2a23;
    padding: 0;
    text-align: start;
  }
  .buttons__get-btn:focus {
    outline: none;
  }
  .buttons__get-btn:active {
    outline: none;
  }
  .gbcomp__img {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
  .text-content__subtitle {
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .gbcomp__text-content {
    max-width: 100%;
  }
  .buttons__close-btn {
    top: 26px;
  }
}
