.CookieBanner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 1.5rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &_text {
    font-size: 16px;
    font-weight: 400;
    color: #111;
  }

  &_button {
    width: 120px;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 10px 16px;
    background-color: #f73232;
    font-size: 16px;
    color: #fff;
  }

  @media (max-width: 768px) {
    &_text {
      font-size: 14px;
    }

    &_button {
      font-size: 14px;
    }
  }
}