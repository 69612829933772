@import "src/styles/shortcuts";
@import "src/styles/mixins";

.CustomCityPicker {
  @include tablet() {
    margin-bottom: 24px;
  }
  @include beMobile() {
    margin-bottom: 24px;
  }

  &_NotFounded {
    font-weight: normal;
    color: $headerLink;
    font-size: 16px;
    line-height: 125%;
    font-style: normal;

    & > a {
      color: $link;
      transition: .2s ease-in-out;

      &:hover {
        color: $linkActive;
      }
    }
  }
}
