@import "colors";

@mixin beforeMobile {
  @media (min-width: 0px) and (max-width: 319px) {
    @content
  }
}

@mixin toIphoneSix {
  @media (min-width: 0px) and (max-width: 374px) {
    @content
  }
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 768px) {
    @content
  }
}

@mixin horMobile {
  @media (min-width: 661px) and (max-width: 768px) {
    @content
  }
}

@mixin beMobile {
  @media (min-width: 0px) and (max-width: 768px) {
    @content
  }
}

@mixin tablet {
  @media (min-width: 769px) and (max-width: 991px) {
    @content
  }
}

@mixin laptop {
  @media (min-width: 992px) and (max-width: 1200px) {
    @content
  }
}

@mixin desktop {
  @media (min-width: 1201px) {
    @content
  }
}

@mixin L {
  @media (min-width: 1000px) {
    @content
  }
}

@mixin S {
  @media (max-width: 999px) {
    @content
  }
}

@mixin NTablet {
  @media (min-width: 0) and (max-width: 1199px) {
    @content
  }
}


@mixin UiLarge {
  @media screen and (min-width: 1920px) {
    @content
  }
}

@mixin UiDesktop {
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    @content
  }
}

@mixin UiLaptop {
  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    @content
  }
}

@mixin UiTablet {
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    @content
  }
}

@mixin UiMobile {
  @media screen and (min-width: 0px) and (max-width: 767px) {
    @content
  }
}

.CustomContainer {
  margin: 0 auto;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 767px) {
    max-width: 312px;
    //padding: 0 24px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    max-width: 704px;
    //padding: 0 32px;
  }
  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    max-width: 1088px;
    //padding: 0 56px;
  }
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1312px;
    //padding: 0 64px;
  }
  @media screen and (min-width: 1920px) and (max-width: 6000px) {
    max-width: 1592px;
    //padding: 0 164px;
  }
}

@mixin SVG($color) {
  & > svg {
    transition: .2s ease-in-out all;

    & > path {
      transition: .2s ease-in-out all;
      stroke: $color;
    }
  }
}

@mixin SVGBehavior($defaultColor, $hoverColor, $clickColor, $disabledColor) {
  user-select: none;
  transition: .2s ease-in-out all;
  @include SVG($defaultColor);

  &:hover {
    @include SVG($hoverColor);
  }

  &:focus {
    @include SVG($hoverColor);
  }

  &:active {
    @include SVG($clickColor);
  }

  &[data-disabled='true'] {
    @include SVG($disabledColor);
  }
}

.GreenButton {
  outline: none;
  border: 1px solid $Green_one;
  border-radius: 4px;
  background-color: $Green_one;
  color: $White;
  padding: 12px;
  transition: .2s ease-in-out all;

  &:hover {
    outline: none;
    background-color: $Green_two;
    border: 1px solid $Green_two;
    color: $White;
  }

  &:active {
    outline: none;
    background-color: $Green_three;
    border: 1px solid $Green_three;
    color: $White;
  }

  &:focus {
    outline: none;
    background-color: $Green_two;
    border: 1px solid $Green_two;
    color: $White;
  }

  &[disabled], &:disabled, &[data-disabled='true'] {
    background-color: $BorderColor;
    border: 1px solid $BorderColor;
  }
}

.RedButton {
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: $Red_two;
  color: $White;
  padding: 12px;
  transition: .2s ease-in-out all;

  &:hover {
    outline: none;
    background-color: $Red_one;
    color: $White;
  }

  &:active {
    outline: none;
    background-color: $Red_three;
    color: $White;
  }

  &:focus {
    outline: none;
    background-color: $Red_three;
    color: $White;
  }

  &[disabled], &:disabled {
    background-color: $BorderColor;
  }
}

.InverseRedButton {
  outline: none;
  border: 1px solid $Red_two;
  border-radius: 4px;
  background-color: transparent;
  color: $Red_two;
  padding: 12px;
  transition: .2s ease-in-out all;

  &:hover {
    outline: none;
    color: $Red_one;
    border-color: $Red_one;
  }

  &:active {
    outline: none;
    color: $Red_three;
    border-color: $Red_three;
  }

  &:focus {
    outline: none;
    color: $Red_one;
    border-color: $Red_one;
  }

  &[disabled], &:disabled {
    outline: none;
    border-color: $BaG_gray_five;
    color: $BaG_gray_five;
  }
}

.BlackButton {
  outline: none;
  border-radius: 4px;
  border: 1px solid $BaG_black;
  color: $BaG_black;
  padding: 12px;
  transition: .2s ease-in-out all;
  background-color: transparent;

  &:hover {
    outline: none;
    border: 1px solid $BaG_gray_two;
    color: $BaG_black;
  }

  &:active {
    outline: none;
    border: 1px solid $BaG_gray_three;
    color: $BaG_black;
  }

  &:focus {
    outline: none;
    border: 1px solid $BaG_gray_two;
    color: $BaG_black;
  }

  & [disabled], &:disabled {
    background-color: transparent;
    border: 1px solid $BaG_gray_five;
    color: $BaG_gray_five;
  }
}
