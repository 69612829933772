@import "src/styles/shortcuts";
@import "src/styles/mixins";
@import "src/styles/fonts";

.CustomDots {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 1;
  box-shadow: none;
  transition: background .5s;
  border: 1px solid grey;
  padding: 0;
  margin: 0 6px 0 0;
  outline: 0;
  cursor: pointer;
}

.CustomDotsActive {
  @extend .CustomDots;
  background: #080808;
}

.CarouselBlock {
  & > div {
    & > ul {
      margin-bottom: 0 !important;
    }
  }
}

@keyframes slideFromRight {
  from {
    left: 100%;
  }
  to {
    left: calc(0% + 40px);
  }
}

@keyframes slideToRight {
  from {
    left: calc(0% + 40px);
  }
  to {
    left: 100%;
  }
}

.MobileRightMenu {
  display: none;
  position: relative;
  transition: .5s ease-in-out all;

  @include NTablet() {
    display: flex;
    width: calc(100% - 40px);
    position: absolute;
    top: 0;
    left: calc(0% + 40px);
    height: 100vh;
    background-color: $White;
    z-index: 9999;
  }

  &[data-opened='true'] {
    display: flex;
    animation-duration: .5s;
    animation-name: slideFromRight;
  }

  &[data-opened='false'] {
    animation-duration: .5s;
    animation-name: slideToRight;
  }

  &_Close {
    position: absolute;
    top: 12px;
    left: 12px;

    & > img {
      width: 24px;
      height: 24px;
    }
  }

  &_Container {
    display: flex;
    flex-direction: column;
    padding: 32px 52px;
    width: 100%;

    &_Logo {
    }

    &_Selector {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 28px;

      .SelectorText {
        display: flex;
        align-items: center;

        & > img {
          margin-right: 8px;
        }

        & > span {
          @extend .Text_text_4;
          color: $BaG_black;
        }
      }

      .SelectorCity {
        display: flex;
        align-items: center;
        width: 100%;

        & > div {
          width: 80%;
          margin-bottom: 0;
        }

        & > img {
          margin-left: 12px;
        }
      }
    }

    &_Links {
      display: flex;
      flex-direction: column;

      & > a {
        @extend .Caption_label;
        color: $BaG_black;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $BaG_black;
        }

        &:active {
          color: $BaG_black;
        }

        &:focus {
          color: $BaG_black;
        }
      }
    }

    &_User {
      display: flex;
      align-items: center;
      margin-top: 24px;

      & > div {
        margin-right: 8px;
      }

      & > span {
        @extend .Text_text_4;
        color: $BaG_gray_one;
      }
    }
  }
}

.MobileFooter_Overflow {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: $White;
  height: 56px;
  z-index: 999;
  display: none;
  @include NTablet() {
    display: flex;
  }
}

.MobileFooter {
  z-index: 1000;
  display: none;
  margin-bottom: env(safe-area-inset-bottom, 20px);

  @include NTablet() {
    display: flex;
    align-items: center;
    position: fixed;
    height: 56px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -4px 16px -4px rgba(51, 51, 51, 0.06);
    background-color: $White;
    z-index: 9999;
  }

  &_Item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $BaG_gray_three;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    height: 100%;
    transition: .2s ease-in-out all;
    z-index: 1;

    &[data-active="true"] {
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid $Red_two;
        height: 2px;
        width: 100%;
      }

      color: $Red_two;

      svg {
        filter: invert(37%) sepia(70%) saturate(1968%) hue-rotate(336deg) brightness(92%) contrast(111%);
      }
    }
  }
}

.Footer {
  z-index: 100;
  padding-top: 64px;
  @include NTablet() {
    padding-bottom: 56px;
  }
  background: #ffffff;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0.01) 10%, white 15%, rgba(255, 255, 255, 0.55) 30%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0.01) 10%, white 15%, rgba(255, 255, 255, 0.55) 30%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0.01) 10%, white 15%, rgba(255, 255, 255, 0.55) 30%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);


  .MainFooter {

    .MainFooterContent {
      padding: 0;
      margin-bottom: 88px;

      .MainFooterContentTop {
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MainFooterContentTopLeft {
          display: flex;
          align-items: center;
          width: 100%;

          .MainFooterContentTopLeftIcons {
            display: flex;
            margin-left: auto;
            margin-right: auto;

            & > a {
              display: flex;
              width: 44px;
              height: 44px;
              align-items: center;
              justify-content: center;
              background: transparent;
              border-radius: 50%;
              font-size: 1.25em;
              transition: all 0.2s ease;
              margin-right: 20px;
              cursor: pointer;

              &:hover {
                box-shadow: 0 4px 10px rgba(43, 46, 74, 0.2);
              }

              & > div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;

                & > img {
                  width: 100%;
                  height: auto;
                  max-width: 20px;
                }
              }
            }

            &VK {
              border: 1px solid #5080B8;

              & > div {
                & > img {
                  margin-top: 2px;
                }
              }
            }

            &FB {
              border: 1px solid #335AA5;

              & > div {
                & > img {
                  width: 10px !important;
                }
              }
            }

            &Twitter {
              border: 1px solid #1DA1F1;
            }

            &Inst {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1px;
              background: #f09433 !important;
              background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
              background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
              background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;

              & > div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $white;
              }
            }

            &OK {
              border: 1px solid #EE8209;

              & > div {
                & > img {
                  width: 12px !important;
                }
              }
            }

            &TenChat {
              border: 1px solid #FC3234;

              & > div {
                & > img {
                  width: 40px !important;
                }
              }
            }

            &TG {
              border: 1px solid #37AEE2;

              & > div {
                & > img {
                  width: 20px !important;
                  margin-right: 4px;
                  margin-top: 2px;
                }
              }
            }
          }
        }

        .MainFooterContentTopRight {
          & > button {
            background-color: #c12b2f;
            border: solid 2px #cc2e32;
            padding: 11px 20px;
            line-height: 1;
            border-radius: 2px;
            margin: 0;
            color: $white;
            transition: all 0.2s ease;
            display: inline-flex;
            font-size: 1.2em;
            min-height: 42px;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
          }
        }
      }

      .MainFooterContentBot {
        display: grid;
        grid-template-columns: 1fr 1fr 0.7fr 1fr 1fr;
        gap: 16px;

        @include UiTablet {
          gap: 12px;
        }

        .MainFooterContentBotDiv {
          display: flex;
          flex-direction: column;

          & > span {
            margin-bottom: 16px;
            color: $title;
            font-weight: 400;
            font-size: 24px;
            line-height: 1;
          }

          .MainFooterContentBotDivNormal {
            display: flex;
            flex-direction: column;

            .MainFooterContentBotNormalLink {
              margin-bottom: 16px;
              @include beMobile() {
                margin-bottom: 8px;
              }

              & > a {
                color: $headerLink;
                transition: all 0.2s ease-in-out;
                font-weight: 400;
                text-decoration: none;
                white-space: nowrap;
              }

              & > a:hover {
                color: $headerLinkActive;
                font-weight: 475;
                transition: all 0.2s ease-in-out;
              }
            }

            .MainFooterContentBotSoonLink {
              @extend .MainFooterContentBotNormalLink;

              & > a {
                color: #00000066;
                cursor: default;
              }

              & > a:hover {
                color: #00000066;
                cursor: default;
              }

              & > a:after {
                content: 'скоро';
                background-color: #c2d5ef;
                font-size: 0.85em;
                font-weight: 400;
                color: black;
                line-height: 1.5;
                padding: 4px 10px;
                border-radius: 32px;
                margin-left: 8px;
                font-family: 'Roboto', sans-serif;
              }
            }

            .MainFooterContentBotDivNormalCarousel {
              @include beMobile() {
                width: 65%;
                margin-top: 8px;
              }
            }
          }
        }
      }

      .MainFooterBtn {
        display: none;
        @media (max-width: 768px) {
          width: 100%;
          height: 55px;
          margin-bottom: 40px;
          background: #2B2E4A;
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .MainFooterBtnAndroid {
        display: none;
        @media (max-width: 768px) {
          display: block;
          width: 100%;
          height: 55px;
          margin-bottom: 40px;
          background: url(/static/images/google-play2.svg) no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .LastFooter {
    //background: rgba(131, 146, 165, 0.2);
    //background: rgba(131, 146, 165, 0.2);
    //background: linear-gradient(0deg, rgba(131, 146, 165, 0.2) 85%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(131, 146, 165, 0.2) 30%, rgba(255, 255, 255, 0) 100%);
    padding: 21px 0;

    & > div {
      display: flex;
      flex-direction: column;

      .def {
        display: block;
        font-size: 0.875em;
        color: #8392a5;
        line-height: 1.42857;
        font-weight: 400;
      }

      .LastFooterText {
        padding: 0;

        .LastFooterTextCop {
          @extend .def;
          margin-bottom: 14px;
        }

        .LastFooterTextRights {
          @extend .def;
          margin-bottom: 4px;
        }

        .LastFooterTextLinks {
          @extend .def;
          margin-bottom: 4px;

          & > a {
            color: $link;
            text-decoration: none;
            font-size: 1em;
            line-height: 1.2;
            display: inline-flex;
            transition: all 0.2s ease;
          }

          & > a:hover {
            color: $linkActive;
          }
        }
      }
    }
  }
}

.Flex {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;

  @media (max-width: 1199px) {
    flex-direction: column !important;
    align-items: start !important;
  }
}

.Pronin {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 8px;
  color: #111;
  font-size: 12px;

  img {
    height: 22px;
  }

  @media (max-width: 1199px) {
    font-size: 9px;
    padding: 20px 0 40px;
    row-gap: 4px;

    img {
      height: 18px;
    }
  }
}

@media (max-width: 768px) {
  .Footer {
    .MainFooter {

      .MainFooterContent {
        padding: 0;
        margin-bottom: 40px;

        .MainFooterContentTop {
          margin-bottom: 16px;

          .MainFooterContentTopLeft {
            display: block;
            width: 100%;

            & > div {
              margin-bottom: 20px;
            }

            .MainFooterContentTopLeftIcons {
              display: flex;
              margin-left: 0;
              justify-content: space-between;

              & > a {
                margin-right: 0;
              }
            }
          }
        }

        .MainFooterContentBot {
          grid-template-columns: none;
          gap: 24px;

          .MainFooterContentBotDiv {

            & > span {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Footer {
    margin-top: 0;

    .LastFooter {
      & > div {
        .LastFooterText {
          padding: 0 0px 32px;
        }
      }
    }
  }
}

.MainFooterModal {
  display: flex;
  justify-content: center;
  padding: 24px 20px !important;
}

.MainFooterModalBtn {
  width: 100%;
  height: 55px;
  background: #27C175;
  border-radius: 6px;
  margin-top: 24px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  outline: none;
}

.MainFooterModalTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2B2E4A;
  display: block;
}

.MainFooterModalText {
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #37333F;
  margin-top: 8px;
  display: block;
}

.MainFooterModalIcon {
  display: block;
  margin: 10px 0 16px 0;
  width: 100%;
  height: 60px;
  background: url(/static/images/footer-ios-icon.svg) no-repeat center;
  background-size: contain;
}

.sendsayForm {
  & > div {
    margin: 0 auto 50px;
    width: 100% !important;
    max-width: 960px;
  }
}