@import "./src/styles/mixins";
@import "./src/styles/fonts";

.UserPhoto {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    border-radius: 50%;
    border: 1px solid $White;
  }
}

.DefaultUserPhoto {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    border-radius: 50%;
    border: 1px solid $White;
  }
}
