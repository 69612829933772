.DSLogo {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > div {
    & > img {
      height: 100%;
    }

    & > span {
      font-family: Fontfabric;
      color: #EF0000;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      transition: .2s ease-in-out;
    }
  }
}
@media screen and (max-width: 1200px) {
  .headerLogo{
    position: relative;
    flex-direction: column;
  }
  .DSLogoActiveLink{
    color: #F73232!important;
    path{
      stroke: #F73232;
    }
  }
  .DSLogo > div > .DSLogoHeader{
    font-family: Inter, sans-serif;
    font-size: 9px;
    line-height: 16px;
    color: #6C7175;
    display: block;
  }
}


@font-face {
  font-family: 'Fontfabric';
  src: url('/static/fonts/Fontfabric - MullerBold.otf');
  src: local('Fontfabric'), local('Fontfabric'),
  url('/static/fonts/Fontfabric - MullerBold.otf') format('otf'),
  url('/static/fonts/Fontfabric - MullerBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
