@import "src/styles/shortcuts";
@import "src/styles/mixins";
@import "src/styles/fonts";

.NewHeader {
  display: flex;
  align-items: center;
  background-color: $White;
  height: 70px;
  padding: 17px 0;
  @extend .Text_text_4;
  color: $BaG_gray_two;

  @media (max-width: 1199px) {
    & > div {
      justify-content: space-between !important;
    }
  }

  &_First {
    padding: 0 32px 0 0;
    cursor: pointer;
    @include beMobile() {
      padding: 0;
    }
  }

  &_MobileHelp {
    display: none;

    @media (max-width: 1199px) {
      display: block;
      // margin-left: 22px;
    }
  }

  &_Second {
    display: flex;
    align-items: center;
    @include NTablet() {
      display: none;
    }

    &_Item {
      margin-right: 48px;
      cursor: pointer;
      transition: .2s ease-in-out all;

      &:hover {
        color: $BaG_gray_one;
      }

      &:last-child {
        margin-right: 0;
      }

    }
  }

  &_Third {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include NTablet() {
      display: none;
    }

    &_Item {
      @include NTablet() {
        display: none;
      }
      margin-right: 32px;
      transition: .2s ease-in-out all;

      &:hover {
        color: $BaG_gray_one;
      }

      &:first-child {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
        display: flex;
        align-items: center;
      }

      &_User {
        cursor: pointer;

        &_Button {
          @extend .RedButton;
        }
      }

      &_DropDown {

      }
    }
  }

  &_Four {
    margin-left: auto;
  }

  .headerDropdownItem {
    background-color: white;
    color: $BaG_gray_two;
    @include NTablet() {
      display: none;
    }

    &:hover {
      background-color: white !important;
      color: $BaG_gray_one !important;
    }

    .header_nav_link {
      @include NTablet() {
        display: none;
      }

      & > a {
        color: $BaG_gray_two;
      }
    }
  }
}

.Preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
}

.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 200;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.custom {
  width: 100%;
  height: 100%;
  position: relative;
}

.customPopup {
  background-color: $white;
  border-radius: 8px;
  position: absolute;
  top: 35px;
  padding: 8px;
  width: 330px;
  border: 1px solid $iconGrayBack;
  box-shadow: 0 4px 10px rgba(43, 46, 74, 0.08);
  z-index: 201;
}

.DropDownClass {
  position: relative;

  &_Menu {
    top: 200% !important;
    //height: 370px;

    &_Selector {
      min-width: 330px;

      &:hover {
        background-color: $white !important;
      }
    }
  }
}

.headerAltLogo {
  display: none;
}

.header {
  padding: 0 32px;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  background: $white;
  box-shadow: 0 1px 1px #F2F2F2;

  &_pwa {
    margin-bottom: 0;
  }

  &_first {
    display: flex;
    align-items: center;

    .howBeginDonor {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    .whereMap {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    & > div {
      margin-right: 16px;
      cursor: pointer;

      @media screen and (min-width: 1200px) {
        margin-right: 32px;
      }


      /* &:not(:first-child) {
         @media screen and (max-width: 1200px) {
           display: none;
         }
       }*/

      & > a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $headerLink;
        transition: .2s ease-in-out;

        &:hover {
          color: $title;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header_secondProfile {
    display: none;
  }

  &_second {
    display: flex;
    align-items: center;

    & > div {
      &:first-child {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }


    &_favorite {
      height: 100%;
      display: flex;
      cursor: pointer;

      & > span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $red;
        display: flex;
        align-items: center;

        & > span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $red;
          margin-left: 4px;
        }
      }

      // @media screen and (max-width: 1200px) {
      //   display: none;
      // }
    }

    &_last {
      display: flex;
      align-items: center;
      margin-left: 32px;
    }
  }

  .headerDropdownTriggerWrapper {
    margin-top: 1px;
  }

  .headerDropdownTrigger {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $headerLink;
    transition: .2s ease-in-out;

    &:hover {
      color: $title;
    }
  }

  .headerGeoTrigger {
    margin-right: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img {
      margin-right: 6px;
    }

    & > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: $title;
      white-space: nowrap;
    }
  }

  .headerDropdownCities {
    margin-bottom: 12px;
    margin-top: 12px;
    min-width: 350px;

    &:hover {
      background-color: white !important;
    }

    .headerDropdownMoscow_Block, .headerDropdownOther_Block {
      max-width: 296px;
      display: flex;
      flex-direction: column;

      &:hover {
        & > span:after {
          content: '';
          width: 6px;
          height: 6px;
          background: $red;
          border-radius: 50%;
          margin-left: 8px;
        }
      }

      & > span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $title;
        margin-bottom: 8px;
        position: relative;
        display: flex;
        align-items: center;
      }

      & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
        color: $headerLink;
        word-break: break-word;
        padding: 0;
        margin: 0;
        width: 100%;
        white-space: normal;
      }
    }

    .headerDropdownMoscow_Block_Active, .headerDropdownOther_Block_Active {
      @extend .headerDropdownMoscow_Block;

      & > span {
        color: $red;
      }
    }
  }

  .headerDropdownItem {
    background-color: white;

    &:hover {
      background-color: white !important;
      color: $title !important;

      .header_nav_link {
        & > a {
          color: $title;
        }
      }
    }
  }

  .header_nav_link {
    font-weight: normal;
    margin-right: 32px;

    &:hover {
      color: $title;
    }

    & > a {
      font-weight: normal;
      transition: .2s ease-in-out;

      &:hover {
        color: $title;
      }
    }
  }

  .login_btn {
    padding: 12px;
    background: $red;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    border: none;
    outline: none;
    margin-left: 1rem;
  }

  .login_btn:hover {
    text-decoration: none;
  }
}


.header_user_logo {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: pink;

  & > span {
    color: white !important;
    font-size: 18px;
  }
}

.header_dropdown_menu {
  color: $blackText;
  z-index: 9999;

  .nestedItem {
    max-width: 288px !important;
    box-sizing: border-box;

    span {
      margin-left: 35px;
      white-space: wrap;
    }
  }

  .show {
    max-width: 288px !important;
    box-sizing: border-box;

    span {
      margin-left: 35px;
      white-space: wrap;
      color: #8a8f93;
      border-bottom: 1px dashed #8a8f93;
      font-size: 11px !important;
      font-weight: 400;
    }

    &:hover {
      background-color: inherit !important;

      span {
        color: #4E5355 !important;
      }
    }
  }

  .labelItem {
    span {
      font-weight: 600 !important;
    }
  }

  .labelItem:hover:not(:last-child) {
    background-color: inherit !important;

    span {
      color: #4E5355 !important;
    }
  }

  & > div {
    width: 288px !important;
    top: 43px !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.06) !important;

    & > div {
      display: flex !important;
      align-items: center !important;

      & > span {
        font-size: 16px !important;
        line-height: 24px !important;
        color: #4E5355 !important;
      }
    }

    & > div:hover {

      & > span {
        color: #131416 !important;
      }
    }
  }
}

.headerMore {
  overflow: hidden;
  @include NTablet() {
    display: none;
  }
}

.header_MobileMenuText {
  font-size: 9px;
  line-height: 16px;
  color: #6C7175;
}

.imgMenuMob {
  width: 100%;
  height: auto;
  max-width: 32px;
  border-radius: 50%;
  max-height: 32px;
}

.header_ActiveLink {
  path {
    stroke: #F73232;
  }

  .header_secondProfile,
  .header_MobileMenuText {
    color: #F73232 !important;
  }
}

@media (max-width: 1200px) {
  .headerAltLogo {
    padding: 15px 33px !important;
    display: block;
    width: 100%;
    background-color: white;
    box-shadow: 0 1px 1px #f2f2f2;
  }
  a.header_ActiveLink {
    width: 100%;

    &::after {
      @include underLineActiveMenuMob(-8px);
    }

    .imgMenuMob {
      max-width: 24px;
      max-height: 24px;
    }
  }
  .headerContainer {
    z-index: 294;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 !important;
    width: 100%;
    max-width: 100%;
    background: white;
    box-shadow: 0 1px 1px #f2f2f2;
  }

  .header {
    .headerBloodLink {
      position: relative;
      display: block;
    }

    .headerDropdownTriggerWrapper {
      display: none;
    }

    &_second {
      & a {
        width: 100%;
      }

      &_last {
        position: relative;
        margin-left: 0;
        text-align: center;
        justify-content: center;
      }
    }

    .header_secondProfile {
      display: block;
    }

    .header_secondProfileName {
      display: none;
    }

    & a {
      font-size: 12px;
    }

    .header_first > div {
      margin-right: 0;
    }

    .header_first,
    .header_second {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      width: 100%;
    }

    &_logo {
      margin-right: 0 !important;
    }

    &_nav:last-child {
      margin: 0 !important;

      & > div > span > span {
        font-size: 12px !important;
      }
    }

    .header_nav_link {
      margin-right: 8px;
    }

    .headerMore {
      order: 2;
      position: relative;
      display: flex;
      height: 100%;
    }

    .login_btn {
      order: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      padding: 0;
      background: transparent;
      border-radius: 0;
      font-size: 9px;
      line-height: 16px;
      color: #6C7175;
    }
  }

  // .header_dropdown_menu {
  //   display: none !important;
  // }


}

@media (max-width: 991px) {
  .header_nav_link {
    display: none;
  }
  .header_nav:last-child {
    & > div {
      display: none;
    }

    .helpProject {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0;
    min-height: 56px;

    &_pwa {
      margin-bottom: 34px;
    }

    .header_nav {

      .header_last {
        margin-left: 17px;
      }
    }
  }
  .headerAltLogo {
    margin: auto;
    padding: 15px 24px !important;
    max-width: 540px;
  }
  .header_dropdown_menu {
    display: none !important;
  }
}
