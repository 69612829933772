:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
    --sal: env(safe-area-inset-left);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

@media screen and (max-width: 480px) {
    .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
        width: 2.5rem;
        line-height: 2.5rem;
    }
}

@media screen and (max-width: 1024px) {
    html, body {
        z-index: 0 !important;
        /*overflow: scroll !important;*/
    }
}

@keyframes anim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes noanim {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
      opacity: 1;
      background-color: white;
    }
    100% {
      opacity: 0;
      background-color: white;
    }
  }
  
.fadeInOut {
    animation: fadeInOut 1s ease-in-out forwards;
}

.NotGrayed {
    z-index: 1;
    position: relative;
    transition: 2s all ease-in-out;
}

.NotGrayed:after {
    content: "";
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-repeat: no-repeat;
    background: linear-gradient(242.77deg, rgba(143, 149, 155, 0.25) 0%, rgba(229, 233, 238, 0) 100%);
    animation: noanim 2s ease-in;
    z-index: -1;
}

.Grayed {
    z-index: 1;
    position: relative;
    transition: 2s all ease-in-out;
}

.Grayed:after {
    content: "";
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 2s all ease-in-out;
    background-repeat: no-repeat;
    background: linear-gradient(242.77deg, rgba(143, 149, 155, 0.25) 0%, rgba(229, 233, 238, 0) 100%);
    animation: anim 2s ease-in-out;
    z-index: -1;
}

.react-datepicker__day--selected {
    background-color: #F73E3E;
}

.form-control:focus {
    box-shadow: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6, * {
    font-family: Inter, Arial, sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: inherit;
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
    color: #86807a;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #86807a;
}

::-ms-input-placeholder {
    color: #86807a;
}

#storyModal {
    padding: 0;
    border-radius: 0;
}

#storyModal > div {
    padding: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 312px;
        padding: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 704px;
        padding: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1088px;
        padding: 0;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1312px;
        padding: 0;
    }
}

@media screen and (min-width: 1920px) and (max-width: 6000px) {
    .container {
        margin: 0 auto;
        width: 100%;
        max-width: 1592px;
        padding: 0;
    }
}

body.dimmable {
    overflow: hidden !important;
}

.cropper-view-box {
    border-radius: 50%;
}

.cropper-face {
    background-color: inherit !important;
}

.cropper-container {
    touch-action: auto !important;
}

.cropper-crop-box {
    touch-action: none;
}

.react-multi-carousel-item[aria-hidden='true'] {
    box-shadow: none;
}

.react-multi-carousel-item[aria-hidden='true'] > div {
    box-shadow: none;
}

.react-multi-carousel-item[aria-hidden='true'] > div > div {
    box-shadow: none;
}

.react-datepicker-popper {
    z-index: 1000;
}

html {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    min-height: calc(100% + env(safe-area-inset-top));
}

.ui.modal > .header {
    padding: 0 !important;
}

.ui.modal > .content {
    padding: 0 !important;
}

.ui.modal > .actions {
    padding: 0 !important;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
    z-index: 10000;
}

@media (max-width: 767px) {
    .ymaps-2-1-79-balloon__content {
        ymaps {
            height: auto !important;
        }
    }
    .ymaps-2-1-79-balloon {
        transform: scale(0.7) !important;
        box-shadow: none !important;
    }
    .ymaps-2-1-79-balloon__layout {
        left: -60px !important;
    }
}

* {
    /*overscroll-behavior: contain*/
}
